<template>
  <div class="message-detail" id="parent" v-if="conversation">
    <div class="card-header  message-header-section">
      <div class="row">
        <div class="col-md-12">
          <div>
            <ConversationAction
              :currentPage="selectCurrentSelectedPage"
              styleOptions="float: right"
              @handleConversationAction="handleConversationAction"/>
            <span @click.prevent="handleRedirection">
              <h4><ion-icon v-if="displayMode ==='mobile'" :icon="chevronBackOutline"></ion-icon>{{ personTitle }}</h4>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body scroll" id="timeline" ref="timelineContainer">
      <MessageTimeline/>
    </div>
    <div class="card-footer child" :style="settings.fg">
      <div class="mb-4">
        <MessageComposer />
      </div>
    </div>
  </div>
  <ConversationNotFound v-else :message="'No conversation has been selected!'"/>
</template>

<script>
import moment from "moment";
import { createNamespacedHelpers } from "vuex";
import { chevronBackOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/vue'
import MessageTimeline from '../timeline/MessageTimeline.vue'
import MessageComposer from '../ComposeMessage.vue'
import ConversationAction from '../../conversation/ConversationAction.vue'
import ConversationNotFound from '../../conversation/ConversationNotFound.vue'
import toaster from '../../../../../lib/toaster'
const SettingsModule = createNamespacedHelpers('setting')
const ConversationModule = createNamespacedHelpers("conversation");

export default {
  name: "MessageDetails",
  components: {MessageTimeline, MessageComposer, ConversationAction, ConversationNotFound, IonIcon},
  setup(){
    return{
      chevronBackOutline
    }
  },
  computed: {
    ...SettingsModule.mapGetters(['selectUserSettings']),
    ...ConversationModule.mapGetters([
      "selectDisplayMode",
      'selecConversationTitle',
      "selectCurrentSelectedPage",
      "selectCurrentConversation",
      ]),

    settings(){
      return this.selectUserSettings
    },
    timestamp() {
      const { created_at } = this.selectCurrentConversation;
      return moment(created_at).format("lll")
    },
    displayMode(){
      return this.selectDisplayMode
    },
    conversation() {
      return this.selectCurrentConversation;
    },
    personTitle(){
      return this.selecConversationTitle
    },
  },
  methods: {
    ...ConversationModule.mapActions([
      'setConversationDisplayMode', 
      'handleSelectedConversation',
      'markConversationAsArchived',
      ]),

    
    scrollToBottom() {
      try{
        const timelineContainer = this.$refs.timelineContainer
        timelineContainer.scrollTop = timelineContainer.scrollHeight
      }
      catch(err){
        // ignore
      }
    },
    handleRedirection(){
      if(this.selectDisplayMode === 'mobile'){
        this.handleSelectedConversation(null)
        this.setConversationDisplayMode(995)
      }
    },
    async handleConversationAction(action){
      if(action === 'ARCHIVED'){
        const response = await this.markConversationAsArchived({
          conversationId: this.conversation._id
        })

        if(response && response.success){
          toaster.success({
            message: response.message,
            position: 'top'
          })
        }
      }
    },
  },
  mounted(){
    this.emitter.on('scrollBottom', () => {
      this.scrollToBottom()
    })
  }
};
</script>
<style scoped>
  #parent {
    min-height: 700px; /* set height as you need */
    display: flex;
    flex-direction: column;
  }
  .child {
    margin-top: auto;
    background: white;
  }
  .message-header-section{
    height: 79px;
  }
  .scroll {
    max-height: 700px !important;
    overflow-y: auto;
  }
  ion-icon {
    font-size: 18px;
    color: blue;
  }
</style>
