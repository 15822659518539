<template>
  <div class="compose-message-wrapper">
    <div class="form-group text-center">
      <small style="display: none" ref="messageStatus">
        {{ messageStatus }}
      </small>
    </div>
    <div class="form-group mb-3">
      <div class="input-group">
        <textarea
        :style="settings.bg"
          style="resize: none"
          v-model="message.text"
          class="form-control"
        ></textarea>
        <div class="input-group-append" :style="settings.bg">
          <button
            class="btn btn-primary-dark"
            @click.prevent="send"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const MessageModule = createNamespacedHelpers("message");
const SettingsModule = createNamespacedHelpers('setting')

export default {
  name: "ComposeMessage",
  components: {},
  data() {
    return {
      message: {
        text: null,
      },
      isThereError: false,
      sendStatus: {
        error: "Cannot send empty message, please enter a message",
        success: "your messge was sent successfully",
      },
    };
  },
  computed: {
    ...SettingsModule.mapGetters(['selectUserSettings']),

    settings(){
      return this.selectUserSettings
    },
    messageStatus() {
      return this.isThereError
        ? this.sendStatus.error
        : this.sendStatus.success;
    },
  },
  methods: {
    ...MessageModule.mapActions(["reply"]),

    async send() {
      const { text } = this.message;

      if (text && text.length) {
        this.isThereError = false;
        const response = await this.reply(text);

        if (response && response.status) {
          if (response.status === 200) {
            this.message.text = null;
            this.$refs.messageStatus.style.display = "block";

            setTimeout(() => {
              this.$refs.messageStatus.style.display = "none";
            }, 5000);
          }
        }
      } else {
        this.isThereError = true;
        this.$refs.messageStatus.style.display = "block";
        this.$refs.messageStatus.style.color = "red";
        setTimeout(() => {
          this.$refs.messageStatus.style.color = "black";
          this.$refs.messageStatus.style.display = "none";
        }, 5000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
ion-item {
  --border-style: none;
}
.input-group {
  height: 30px;
}
.input-group textarea {
  height: 50px;
  border-radius: 10px;
}
.input-group .btn {
  // background-color: #1982fc;
  color: white;
  height: 50px;
  border: 1px solid white;
}
</style>