<template>
  <ion-list>
      <ion-item>
        <ion-thumbnail slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-thumbnail>
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-thumbnail slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-thumbnail>
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
    </ion-list>
</template>

<script>
import { IonSkeletonText, IonThumbnail, IonLabel, IonList, IonItem } from '@ionic/vue'
export default {
  name: 'ConversationItemSkeleton',
  components: {
    IonSkeletonText, IonThumbnail, IonLabel, IonList, IonItem 
  }
}
</script>