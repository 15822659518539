<template>
  <div>
    <div
      class="card convo-card mb-3"
      :style="settings.bg"
      @click.prevent="showMessageDetails(conversation)"
    >
      <div class="row">
        <div class="col-3 col-lg-3">
          <img :src="avatar" class="card-img" alt="avatar image" />
        </div>
        <div class="col-7 col-lg-7">
          <div class="card-body">
            <div class="card-title">
              <span><strong>{{ conversationTitle }}</strong></span>
            </div>
          </div>
        </div>
        <div class="col-2 col-lg-2">
          <div class="unread-message text-right" v-if="hasNewMessages">
            <div class="blue-dot"></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center timestamp">
          <small class="text-muted timestamp-text">{{ timestamp }}</small>
        </div>
      </div>
    </div>
    <!-- conversation wrapper end -->
    <!-- <ConversationMessageModal/> -->
  </div>
</template>

<script>
import moment from "moment";
import { createNamespacedHelpers } from "vuex";
const AvatarModule = createNamespacedHelpers("avatar");
const SettingsModule = createNamespacedHelpers('setting')
const ConversationModule = createNamespacedHelpers("conversation");

export default {
  name: "ConversationItem",
  props: ["conversation"],
  data(){
    return {
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    ...AvatarModule.mapGetters(["avatar"]),
    ...SettingsModule.mapGetters(['selectUserSettings']),

    settings(){
      return this.selectUserSettings
    },
    timestamp() {
      return `Last updated on ${moment(this.conversation.updatedAt).format(
        "LLL"
      )}`;
    },
    hasNewMessages() {
      return this.conversation.hasNewMessages;
    },
    conversationTitle() {
      const restrictedNumber = "+14156884656";
      const { from } = this.conversation.parties;
      const { phone, name } = this.conversation.parties.person;
      return name ? name : phone === restrictedNumber ? from : phone;
    },
  },
  methods: {
    ...ConversationModule.mapActions([
      "markConversationAsRead",
      "setConversationDisplayMode",
      "handleSelectedConversation",
    ]),

    async showMessageDetails(data) {
      this.emitter.emit('scrollBottom', {})
      this.handleSelectedConversation(data);
      this.setConversationDisplayMode(this.windowWidth)
      // remove the new messages flag
      if (this.hasNewMessages) {
        await this.markConversationAsRead(this.conversation);
      }
    },
    handleResizing(){
      window.onresize = () => {
        this.windowWidth = window.innerWidth
      }
    }
  },
  mounted(){
    this.handleResizing()
  }
};
</script>
<style scoped>
.convo-card {
  height: 75px;
  border-radius: 10px;
  cursor: pointer;
}
.convo-card:hover {
  background-color: lightgrey;
}
img {
  height: 50px;
  width: 50px;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 45px;
}
.card-title {
  float: left;
  margin-top: -10px;
}
.timestamp {
  margin-top: -15px;
}
.timestamp-text {
  font-size: 10px;
}
.unread-message .blue-dot {
  margin-top: 30px;
  background-color: #1982fc;
  height: 10px;
  width: 10px;
  border-radius: 5px;
}
</style>


    