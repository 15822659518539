<template>
  <div class="menu-option">
    <ion-icon
      :style="styleOptions ? styleOptions : ''"
      id="conversation-toggler"
      @click.prevent="togglePopoverStatus"
      :icon="ellipsisHorizontalOutline">
    </ion-icon>
    <ion-popover
      :is-open="isPopoverOpened"
      :arrow="false"
      :show-backdrop="false"
      :dismiss-on-select="true"
      @didDismiss="togglePopoverStatus"
      trigger="conversation-toggler">
      <ion-content >
        <ion-list lines="none" :style="settings.fg">
          <ion-item v-for="item in menuOptions" :key="item" @click.prevent="setConverstionPage(item.type)" :style="settings.fg">
            <ion-icon :icon="item.icon" style="margin-right: 10px" class="text-muted"></ion-icon>{{ item.name }}
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-popover>
  </div>
  <div class="selected-menu">
    <div class="text-center">
      <h1><strong>{{ selectCurrentSelectedPage }}</strong></h1>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { ellipsisVerticalOutline, ellipsisHorizontalOutline, 
fileTrayFullOutline, documentTextOutline, mailUnreadOutline, archiveOutline, createOutline} from "ionicons/icons";
import { IonIcon, IonItem, IonList, IonPopover, IonContent } from "@ionic/vue";
const ConversationModule = createNamespacedHelpers('conversation')

export default {
  name: "ConversationPageSelecor",
  props: {
    styleOptions: {
      type: String,
    },
    settings: {
      type: Object
    }
  },
  data() {
    return {
      menuOptions: [
        {
          name: 'Inbox',
          type: 'INBOX',
          icon: fileTrayFullOutline,
        },
        {
          name: 'Drafts',
          type: 'DRAFTS',
          icon: documentTextOutline,
        },
        {
          name: 'Unread',
          type: 'UNREAD',
          icon: mailUnreadOutline,
        },
        {
          name: 'Archived',
          type: 'ARCHIVED',
          icon: archiveOutline,
        },
        {
          name: 'New message',
          type: 'NEW_MESSAGE',
          icon: createOutline,
        }
      ],
      isPopoverOpened: false,
    };
  },
  setup() {
    return {
      createOutline,
      archiveOutline,
      mailUnreadOutline,
      fileTrayFullOutline,
      documentTextOutline,
      ellipsisVerticalOutline,
      ellipsisHorizontalOutline,
    };
  },
  components: {
    IonIcon,
    IonItem,
    IonList,
    IonPopover,
    IonContent,
  },
  computed: {
    ...ConversationModule.mapGetters(['selectCurrentSelectedPage']),

  },
  methods: {
    ...ConversationModule.mapActions(['setCurrentSelectedPage']),

    setConverstionPage(page){
      if((page !== 'NEW_MESSAGE') && (page !== 'DRAFTS')){
        this.setCurrentSelectedPage(page)
      }
    },
    togglePopoverStatus() {
      this.isPopoverOpened = !this.isPopoverOpened
    },
  },
};
</script>
