<template>
  <ion-page>
    <ion-header>
      <ion-toolbar></ion-toolbar>
    </ion-header>
    <ion-content :style="settings.bg">
      <div class="container">
        <div class="message-wrapper mt-4" :style="settings.fg">
          <div class="row">
            <div class="col-lg-4" :hidden="displayMode === 'mobile'">
              <div class="card" :style="settings.fg">
                <div class="card-header">
                  <ConversationPageSelecor :settings="settings" styleOptions="float: right" />
                </div>
                <div class="card-body scroll" >
                  <ConversationList :style="settings.fg"/>
                </div>
              </div>
            </div>
            <div class="col-lg-8 message-section" :style="settings.fg">
              <MessageDetails/>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import router from "@/router";
import { mapActions } from "vuex";
import { IonPage, IonContent, IonToolbar, IonHeader } from "@ionic/vue";
import ConversationList from "../conversation/ConversationList.vue";
import ConversationPageSelecor from "../conversation/ConversationPageSelector.vue";
import { createNamespacedHelpers } from "vuex";
import MessageDetails from './v2/MessageDetailsV2.vue'
const ConversationModule = createNamespacedHelpers("conversation");
const SettingsModule = createNamespacedHelpers('setting')

export default {
  name: "Message",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    ConversationList,
    ConversationPageSelecor,
    MessageDetails,
  },
  data() {
    return {
      
    };
  },
  computed: {
    ...SettingsModule.mapGetters(['selectUserSettings']),
    ...ConversationModule.mapGetters(["selectCurrentSelectedPage", "selectDisplayMode"]),
    
    settings(){
      return this.selectUserSettings
    },
    displayMode(){
      return this.selectDisplayMode
    }
  },
  methods: {
    ...mapActions(["changePageTitle"]),

    async setPageTitle() {
      await this.changePageTitle(router.currentRoute._value.name);
    },
  },
  mounted() {
    this.setPageTitle();
  },
};
</script>

<style scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 12%;
  transform: translateY(-50%);
}
.scroll {
  max-height: 700px !important;
  overflow-y: auto;
}
.card {
  border-radius: 0px;
}
@media screen and (min-width: 999px) {
  .message-section {
    margin-left: -13px;
    border-left: 1px solid lightgrey;
  }
}

</style>
