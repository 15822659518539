<template>
  <div>
    <div class="timeline" 
    v-for="message in messages" 
    :key="message._id">
      <div
        class="timeline-container left"
        v-if="message.direction === 'inbound-api'"
      >
        <div class="content inbound-message">
          <p>{{ message.body }}</p>
          <small class="text-muted"
            ><span class="who-sent-it">Them:</span> {{ timestamp(message.date_updated) }}
          </small>
        </div>
      </div>
      <div
        class="timeline-container right"
        v-if="message.direction === 'outbound-api'"
      >
        <div class="content outbound-message">
          <p>{{ message.body }}</p>
          <small class="text-muted"
            ><span class="who-sent-it">You: </span> {{ timestamp(message.date_updated) }}
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { createNamespacedHelpers } from "vuex";

const MessageModule = createNamespacedHelpers("message");

export default {
  name: "MessageTimeline",
  components: {
  },
  computed: {
    ...MessageModule.mapGetters(["selectConvoMessages"]),

    messages() {
      return this.selectConvoMessages;
    },
  },
  methods: {
    timestamp(datetime) {
      return moment(datetime).format("LLL");
    },
  },
};
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}

body {
  background-color: #474e5d;
  font-family: Helvetica, sans-serif;
}

/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #242526;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #207cd8;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid #ff9f55;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent #242526;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid #242526;
  border-width: 10px 10px 10px 0;
  border-color: transparent #242526 transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.content {
  padding: 20px 30px;
  background-color: #242526;
  position: relative;
  border-radius: 6px;
  color: white;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-container::before {
    left: 60px;
    border: medium solid #242526;
    border-width: 10px 10px 10px 0;
    border-color: transparent #242526 transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}
// reorder this card on the smaller screen
@media screen and (max-width: 768px) {
  .inbound-message {
    background-color: #43CC47
  }
  .outbound-message {
    background-color: #1982FC
  }
  .outbound-message small {
    color: rgb(52, 50, 50) !important
  }
  .who-sent-it {
    display: none;
  }
}  
</style>
