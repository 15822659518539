<template>
  <div>
    <ion-icon
      :style="styleOptions ? styleOptions : ''"
      id="action-toggler"
      @click.prevent="togglePopoverStatus"
      :icon="ellipsisHorizontalOutline">
    </ion-icon>
    <ion-popover
      :is-open="isPopoverOpened"
      :arrow="false"
      :show-backdrop="false"
      :dismiss-on-select="true"
      @didDismiss="togglePopoverStatus"
      trigger="action-toggler">
      <ion-content>
        <ion-list lines="none" :style="settings.fg">
          <ion-item  v-for="action in actionsMenu" :key="action" @click.prevent="requestAction(action.type)" :style="settings.fg">
            {{ currentPage === action.type ? action.reverse : action.name }}
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-popover>
  </div>
</template>

<script>
import { ellipsisVerticalOutline, ellipsisHorizontalOutline, fileTrayFullOutline } from "ionicons/icons";
import { IonIcon, IonItem, IonList, IonPopover, IonContent } from "@ionic/vue";
import { createNamespacedHelpers } from "vuex"
const SettingsModule = createNamespacedHelpers('setting')

export default {
  name: "ConversationAction",
  props: {
    styleOptions: {
      type: String,
    },
    currentPage: {
      type: String,
    },
  },
  data() {
    return {
      actionsMenu: [
        {
          name: 'Archive',
          type: 'ARCHIVED',
          reverse: 'Restore',
        },
        {
          name: 'Mark read',
          type: 'MARK_READ',
        },
        {
          name: 'Preferences',
          type: 'PREFERENCES',
        }
      ],
      isPopoverOpened: false,
    };
  },
  setup() {
    return {
      fileTrayFullOutline,
      ellipsisVerticalOutline,
      ellipsisHorizontalOutline,
    };
  },
  components: {
    IonIcon,
    IonItem,
    IonList,
    IonPopover,
    IonContent,
  },
  computed: {
    ...SettingsModule.mapGetters(['selectUserSettings']),

    settings(){
      return this.selectUserSettings
    }
  },
  methods: {
    requestAction(action){
      this.$emit('handleConversationAction', action)
    },
    togglePopoverStatus() {
      this.isPopoverOpened = !this.isPopoverOpened
    },
  },
};
</script>
<style scoped>
  
</style>