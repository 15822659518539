<template>
  <div>
    <SearchBar
      :expanded="true"
      @searchingFor="searching"
    />
    <hr>
    <div>
      <div class="conversations-list" v-for="conversation in conversations" :key="conversation._id">
      <ConversationItem :conversation="conversation" v-if="!isLoading"/>
      </div>
      <ConversationNotFound v-if="!isLoading && !conversations.length" :message="'No conversation found!'"/>
      <ConversationItemSkeleton v-if="isLoading"/>
      </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import ConversationItem from './ConversationItem.vue'
import SearchBar from '../search-bar/SearchBar.vue'
import ConversationItemSkeleton from './skeleton-loaders/ConversationItemSkeleton.vue'
import ConversationNotFound from './ConversationNotFound.vue'

const MessageModule = createNamespacedHelpers('message')
const ConversationModule = createNamespacedHelpers('conversation')

export default {
  name: "ConversationList",
  components: { ConversationItem, SearchBar, ConversationItemSkeleton, ConversationNotFound},
  data() {
    return {

    }
  },
  computed: {
    ...MessageModule.mapGetters(['selectMessages']),
    ...ConversationModule.mapGetters(['selectConversations', 'selectIsLoading']),

    isLoading(){
      return this.selectIsLoading
    },
    conversations(){
      return this.selectConversations
    }
  },
  methods: {
    ...ConversationModule.mapActions(['fetchConversations', 'searchConversations']),

    searching(searchString){
      this.searchConversations(searchString)
    }
  },
  async mounted(){
    await this.fetchConversations()
  }
}
</script>

<style scoped>
  .scroll {
    max-height: 100px;
    overflow-y: auto;
  }
</style>